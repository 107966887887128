<script lang="ts">
	import PageHeader from '$lib/components/page-header.svelte';
	import Tags from '$lib/components/tags.svelte';
	import Posts from '$lib/components/posts.svelte';
	import { siteBaseUrl } from '$lib/meta';
	import type { PageData } from './$types';
	import type { Post } from '$lib/posts';

	export let data: PageData;
	export const { tags, posts } = data;
</script>

<svelte:head>
	<title>Home</title>
</svelte:head>

<section>
	<PageHeader>About BLOG.siposdani87</PageHeader>

	<h2>Interesting topics for web developers</h2>
	<p>
		Hi, my name is <a href={siteBaseUrl} target="_blank" rel="noreferrer">Dániel Sipos</a>, a senior
		software engineer. I tell the story about this BLOG to you. First of all, I wanted to create a
		blog web application, that displays wonderful markdown articles via (<a
			href="https://en.wikipedia.org/wiki/Markdown"
			target="_blank"
			rel="noreferrer">MD</a
		>/<a href="https://mdxjs.com" target="_blank" rel="noreferrer">MDX</a>/<a
			href="https://mdsvex.com"
			target="_blank"
			rel="noreferrer">MDsveX</a
		>). After it, I am thinking about, how to use it for useful purposes. There are a lot of
		developer issues, which I met in the past and for sure I will meet in the present. For some, it
		took a long time to find a good solution on the internet. Many times there was missing or
		deficient information and I had to do research work. So I will share these problems and my
		solutions as markdown articles with code parts. Finally, I have decided to deploy this app to
		the server. Let's look around and I hope you find, what you are looking for...
	</p>

	<h3>Latest posts</h3>
	<Posts posts={data.posts} />

	<div class="flex pt-2 pb-5 justify-center">
		<a
			href="/posts"
			class="bg-primary px-4 py-1 text-lg border-2 border-primary text-white rounded-full hover:bg-white dark:hover:bg-black hover:text-primary"
			>More posts</a
		>
	</div>

	<h2>Tag Cloud</h2>
	<Tags {tags} />
</section>

<style lang="postcss">
	h2,
	h3 {
		@apply mt-3 mb-3;
	}
	p {
		@apply mb-5;
	}

	p a {
		@apply font-bold underline decoration-primary;
	}

	p a:hover {
		@apply text-primary;
	}
</style>
